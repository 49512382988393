import React from "react";
import { Row, Col, Form, Input, Button } from "antd";

const Corporation = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="w-full pt-20 mb-20">
        <h3 className="mb-1">與Skyline Film合作</h3>
        <p className="text-SKF-G-002 m-0">
          Skyline film 提供客製化活動以及年度廣告合約的活動方式
        </p>
      </div>
      <div className="w-full pb-20">
        <Row>
          <Col
            sm={{ span: 10, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            lg={{ span: 8, offset: 3 }}
            className="mb-10 desktop:mb-0"
          >
            <div className="bg-[url('assets/corporation/left.png')] h-320 rounded-3xl"></div>
            <h4 className="text-left mb-0">客製化品牌專場</h4>
            <p className="text-left mt-0 text-SKF-G-001">
              藉由Skyline Film獨特的屋頂氛圍，來打造專屬於品牌的電影院吧!
            </p>
          </Col>
          <Col
            sm={{ span: 10, offset: 2 }}
            xs={{ span: 22, offset: 1 }}
            lg={{ span: 8, offset: 2 }}
          >
            <div className="bg-[url('assets/corporation/right.png')] h-320 rounded-3xl"></div>
            <h4 className="text-left mb-0">Skyline Film 年度廣告合約</h4>
            <p className="text-left mt-0 text-SKF-G-001">
              您將跟著屋頂電影院在北、高的自售場次活動進行巡迴，並與屋頂電影院的其他夥伴們一起與觀眾進行互動。
            </p>
          </Col>
        </Row>
      </div>
      <div className="w-full bg-SKF-G-001 px-4 desktop:px-56 py-6 desktop:py-12 tablet:px-20">
        <h4 className="text-left text-black">聯絡我們 Contact US</h4>
        <Form
          layout="vertical"
          name="contact"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col sm={{ span: 12, offset: 0 }} xs={{ span: 24, offset: 0 }}>
              <Form.Item name="first_name" label="First Name">
                <Input placeholder="名字" className="p-2" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 12, offset: 0 }} xs={{ span: 24, offset: 0 }}>
              <Form.Item name="last_name" label="Last Name">
                <Input placeholder="姓氏" className="p-2" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
              <Form.Item name="email" label="Email">
                <Input placeholder="電子信箱" className="p-2" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
              <Form.Item name="content" label="Content">
                <Input.TextArea
                  autoSize={{ minRows: 5 }}
                  placeholder="內容"
                  className="p-2"
                />
              </Form.Item>
            </Col>
            <Col
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              className="text-left"
            >
              <Form.Item>
                <Button
                  className="bg-black text-white py-1 h-auto"
                  htmlType="submit"
                >
                  送出Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default Corporation;
