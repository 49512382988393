import styled from "styled-components";
import { Input } from "antd";

export const InputPlug = styled(Input)`
  :-internal-autofill-selected {
    background-color: green !important;
  }
`;

export const InputPassword = styled(Input.Password)`
  &:-internal-autofill-selected {
    background-color: red !important;
  }
`;
