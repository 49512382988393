import React from "react";
import CollapsePlug from "components/faq";

import { Form, Row, Col, Input, Button, theme, message } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import { apiPostContactUs } from "api";

const About = () => {
  const { token } = theme.useToken();

  const itemStyles = {
    marginBottom: 24,
    background: "#282828",
    color: "red",
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "如果下雨怎麼辦？",
      style: itemStyles,
      children: (
        <p>
          1. 我們會根據夥伴 天氣風險
          WeatherRisk 所提供的天氣資訊，如遇天氣狀態不佳，最晚會在活動前一天公告取消。2.
          如遇颱⾵或其他不可抗⼒之⾃然因素導致活動必須取消/延期，我們將會透過活動通Accupass購票頁面(本頁面)及Skyline
          Film
          Facebook官方粉絲團進⾏公告。若牽涉退票事宜，我們將會按照相關法令規定，並統⼀透過活動通Accupass辦理退費。
        </p>
      ),
    },
    {
      key: "2",
      label: "可以攜帶外食嗎？",
      style: itemStyles,
      children: (
        <p>我們現場會提供百威金尊以及爅登煙燻現場販售。但對於外食沒有限制</p>
      ),
    },
  ];

  const handleSend = async (value) => {
    try {
      const res = await apiPostContactUs(value);
      message.success("聯繫表單成功送出");
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="w-full px-2 py-4 desktop:px-32 desktop:py-10">
        <div className="rounded-3xl laptop:h-600 tablet:h-400 mobile:h-400 bg-[url('assets/about/about.png')] bg-cover bg-center bg-no-repeat"></div>
      </div>

      {/* 關於我們 */}
      <div className="w-full desktop:px-56 mobile:px-2 tablet:px-20 pb-16 text-left">
        <>
          <h4 className="m-0 pt-8 pb-6 desktop:pt-10 desktop:pb-8  text-white flex">
            關於我們
            <span className="hidden desktop:block">About Us</span>
          </h4>
          <p className="text-SKF-G-001">
             每一部電影，都是一個故事。
            <br />
            Skyline
            Film用最有趣且獨特的方式放電影，我們讓看電影這件小事，變得很不一樣。Skyline
            Film捨棄了一般音響，戴上專屬無線耳機，沈浸在最愛的台詞或熟悉的配樂中，更加貼近你愛的電影。
            <br />
            <br />
            有了無線耳機，你不再受到空間的限制，盡情享受城市夜景與電影的完美結合。我們邀請您一起來體驗Skyline
            Film獨一無二的放映方式，更多放映活動消息請鎖定我們的Facebook與IG。
            <br />
            <br />
            聯絡我們
            <br />
            <br />
            <a
              href="mailto:support@rftexp.com"
              className="bg-black text-white mobile:w-10/12 desktop:w-1/3 desktop:flex justify-between rounded-lg text-base underline"
            >
              <p className="text-SKF-G-001">support@rftexp.com</p>
            </a>
            <br />
            {/* <a href="#" className="border-b-SKF-G-001 border-b">
              Facebook
            </a> */}
            <Button
              type="link"
              className="p-0"
              onClick={() =>
                window.open("https://www.facebook.com/Skylinefilm")
              }
            >
              <p className="text-SKF-G-001 underline">Facebook</p>
            </Button>
            <br />
            <Button
              type="link"
              className="p-0"
              onClick={() =>
                window.open("https://www.instagram.com/skylinefilmtw")
              }
            >
              <p className="text-SKF-G-001 underline">Instagram</p>
            </Button>
          </p>
        </>
      </div>
      {/* 常見問題 */}
      <div className="w-full bg-SKF-G-001 desktop:h-[auto] desktop:px-56 mobile:px-2 tablet:px-20 pb-3 text-left">
        <h4 className="m-0 pt-8 pb-6 desktop:pt-10 desktop:pb-8  text-black flex">
          常見問題
          <span className="hidden desktop:block">FAQ</span>
        </h4>
        <CollapsePlug
          items={items}
          bordered={false}
          defaultActiveKey={["1"]}
          onChange={onChange}
          className="text-left"
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          style={{
            background: "#CDCDCD",
            color: "white",
          }}
        />
        <h4 className="text-black flex">
          聯絡我們<span className="hidden desktop:block">Contact US</span>
        </h4>
        <Form onFinish={handleSend}>
          <Row gutter={16}>
            {" "}
            <Col sm={{ span: 12 }} xs={{ span: 24 }} className="mb-4">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "此欄位必填",
                  },
                ]}
              >
                <Input placeholder="name" size="large" className="p-2" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 12 }} xs={{ span: 24 }} className="mb-4">
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "此欄位必填且需滿足email格式",
                  },
                ]}
              >
                <Input placeholder="email" size="large" className="p-2" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24 }} xs={{ span: 24 }} className="mb-4">
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "此欄位必填",
                  },
                ]}
              >
                <Input
                  placeholder="phone number"
                  size="large"
                  className="p-2"
                />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24 }} xs={{ span: 24 }} className="mb-4">
              <Form.Item
                name="content"
                rules={[
                  {
                    required: true,
                    message: "此欄位必填",
                  },
                ]}
              >
                <Input.TextArea
                  autoSize={{
                    minRows: 5,
                  }}
                  placeholder="content"
                  size="large"
                  className="p-2"
                />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24 }} xs={{ span: 24 }}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="bg-black text-white py-2 px-5 h-auto"
                >
                  送出 Send
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default About;
