import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isChartOpen: false,
  cartsAmount: 0,
};

export const chartsSlice = createSlice({
  name: "charts",
  initialState,
  reducers: {
    setIsChartOpen: (state, action) => {
      console.log("ss", state);
      console.log("aa", action);
      state.isChartOpen = action.payload;
    },
    updateCartsAmount: (state, action) => {
      state.cartsAmount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsChartOpen, updateCartsAmount } = chartsSlice.actions;

export default chartsSlice.reducer;
