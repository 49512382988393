import React from "react";
import { CollapseComp } from "./Styled";
import { CaretRightOutlined } from "@ant-design/icons";

const CollapsePlug = ({ items, onChange }) => {
  return (
    <CollapseComp
      items={items}
      bordered={false}
      defaultActiveKey={["1"]}
      onChange={onChange}
      className="text-left"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{
        background: "#CDCDCD",
        color: "white",
      }}
    />
  );
};

export default CollapsePlug;
