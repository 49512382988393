import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
import tw from "dayjs/locale/zh-tw";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(tw);

export const convertTimeStamp = (
  dateTime,
  formatString = "YYYY-MM-DD HH:mm"
) => {
  const _dateTime = dayjs.unix(dateTime);
  return _dateTime.tz("Asia/Taipei").format(formatString);
};
