import React from "react";
import { notification } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

export const apiSuccessHandler = (res, successContent = "") => {
  // 預設 successContent = 空字串，如果今天沒有特別設定會進到 default case
  switch (true) {
    case res.status == 200 && successContent.length > 0:
      notification.success({
        message: `操作成功`,
        description: successContent,
        icon: <CheckCircleFilled style={{ color: "#67c23a" }} />,
      });
      break;
    default:
      notification.success({
        message: `操作成功`,
        description: `資料已經成功更新`,
        icon: <CheckCircleFilled style={{ color: "#67c23a" }} />,
      });
      break;
  }
};

export const apiErrorHandler = (err, errorContent) => {
  console.log("err", err);
  if (err) {
    switch (err.status) {
      case 400:
      case 401:
      case 403:
      case 404:
      case 500:
        notification.error({
          message: errorContent ? errorContent : "",
          // message: `更新資料失敗（錯誤代碼：${err.data.code}）`,
          description: `
            ${err.data.msg}
          `,
          icon: <CloseCircleFilled style={{ color: "#f56c6c" }} />,
          duration: 8,
        });
        break;
      default:
        notification.error({
          message: `更新資料失敗`,
          // message: `更新資料失敗（錯誤代碼：${err.data.code}）`,
          description: `
            ${err.data.msg}
          `,
          icon: <CloseCircleFilled style={{ color: "#f56c6c" }} />,
          duration: 8,
        });
        break;
    }
  }
};
