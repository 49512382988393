import React, { useState, useEffect } from "react";

import { PlusOutlined, MinusOutlined, DeleteOutlined } from "@ant-design/icons";

import { TablePlug, NumberSelector } from "./Styled";
import { apiGetCarts, apiPutCarts } from "api/api";

import { convertTimeStamp } from "utility/functions/convertTimestamp";
import { useSelector, useDispatch } from "react-redux";
import { updateCartsAmount } from "store/slice/index";

const ChartTable = ({ setCartsAmount }) => {
  const dispatch = useDispatch();

  const [record, setRecord] = useState({}); // 在點擊增減的同時，寫入record
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, updateTableData] = useState([]);

  const open = useSelector((state) => state.charts.isChartOpen);

  const normal = "票券類型：一般票種 Ticket Type: General Admission";
  const pr = "票券類型：免費票種 Ticket Type: Free Admission";

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await apiGetCarts();
      const { details } = res.data.content;
      updateTableData(details);
      console.log("resss", res);
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [open]);

  useEffect(() => {
    dispatch(updateCartsAmount(tableData.length));
  }, [tableData, record]);

  useEffect(() => {
    setCartsAmount(tableData.length);
  }, [tableData]);

  const updatedData = async (data = tableData) => {
    setIsLoading(true);
    try {
      const res = await apiPutCarts(data);
      fetchData();
      console.log("更新", res);
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "產品 Item",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return (
          <>
            <p className="mb-2">{record.groupTitle}</p>
            <p className="mb-2">{record.title}</p>
            <p className="text-SKF-G-002 text-14">{`${convertTimeStamp(
              record.startDateTime,
              "MM/DD ddd - HH:mm"
            )}（opens at ${convertTimeStamp(
              record.effectiveStartDateTime,
              "HH:mm"
            )}）`}</p>
            <p>NT${record?.price}</p>
            <p className="text-SKF-G-002 text-14">
              {record?.type == 1 ? normal : pr}
            </p>
            <NumberSelector
              controls={false}
              min={1}
              max={10}
              value={record?.amount}
              readOnly={true}
              className="mt-5 w-1/2 md:w-1/6"
              addonBefore={
                <MinusOutlined
                  onClick={() => calculate(record, "minus")}
                  className="text-white "
                />
              }
              addonAfter={
                <PlusOutlined
                  onClick={() => calculate(record, "plus")}
                  className="text-white "
                />
              }
            />
            <DeleteOutlined
              className="mt-5 pl-2 text-2xl"
              style={{
                lineHeight: "32px",
                height: "32px",
              }}
              onClick={() => handleDelete(record)}
            />
          </>
        );
      },
    },
    {
      title: "總計 Subtotal",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (text, record) => {
        return <p className="m-0">NT${record?.amount * record?.price}</p>;
      },
    },
  ];

  const handleDelete = (record) => {
    const filterData = tableData.filter(
      (item) => item.sourceId !== record.sourceId
    );
    updatedData(filterData);
  };

  const calculate = (record, type) => {
    setRecord(record);
    console.log(record);
    updateTableData([
      ...tableData.map((item) => {
        if (item?.sourceId == record?.sourceId) {
          switch (type) {
            case "plus":
              if (item.amount >= 1) item.amount += 1;
              else item.amount = 1;
              break;
            case "minus":
              if (item.amount > 1) item.amount -= 1;
              else item.amount = 1;
              break;
            default:
              break;
          }

          return item;
        } else return item;
      }),
    ]);
    updatedData();
  };

  // useEffect(() => {
  // const toBeUpdatedData = tableData.map((item) => {
  //   delete item.effectiveEndDateTime;
  //   delete item.effectiveStartDateTime;
  //   delete item.endDateTime;
  //   delete item.groupTitle;
  //   delete item.price;
  //   delete item.startDateTime;
  //   delete item.title;
  //   delete item.total;
  //   delete item.type;
  // });
  // updatedData(toBeUpdatedData);
  // updateTableData([
  //   ...tableData.map((item) => {
  //     if (item.id == record?.id) {
  //       item.total = item.amount * item.price;
  //       return item;
  //     } else return item;
  //   }),
  // ]);
  // }, [record.amount]);

  return (
    <TablePlug
      bordered={false}
      columns={columns}
      dataSource={tableData}
      pagination={false}
      loading={isLoading}
      className="border-none bg-SKF-G-004 [&_.ant-table-tbody>tr.ant-table-placeholder]:bg-transparent [&_.ant-empty-description]:text-white"
      summary={(pageData) => {
        let ttl = 0;
        pageData.forEach(({ price, amount }) => {
          ttl += price * amount;
        });
        return (
          <>
            <TablePlug.Summary.Row className="bg-SKF-G-004 text-white">
              <TablePlug.Summary.Cell index={0}>
                <h4>總計</h4>
              </TablePlug.Summary.Cell>
              <TablePlug.Summary.Cell index={1} colSpan={2}>
                <h4>NT${ttl}</h4>
              </TablePlug.Summary.Cell>
            </TablePlug.Summary.Row>
          </>
        );
      }}
    ></TablePlug>
  );
};

export default ChartTable;
