import styled from "styled-components";
import { InputNumber, Table } from "antd";

export const SKF_G_004 = "#282828";
export const SKF_G_003 = "#5F5F5F";

export const TablePlug = styled(Table)`
  .ant-table {
    border: none;
    background: ${SKF_G_004};
  }
  .ant-table-cell-row-hover {
    background: ${SKF_G_004} !important;
    cursor: pointer;
  }
  thead.ant-table-thead .ant-table-cell {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${SKF_G_003};
    background: ${SKF_G_004};
    color: #ffffff;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &::before {
      background-color: transparent !important;
    }
  }
  tbody.ant-table-tbody .ant-table-cell {
    border: none;
    background: ${SKF_G_004};
    color: #ffffff;
    vertical-align: top;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  tfoot.ant-table-summary .ant-table-cell {
    border-bottom: none;
    border-top: 1px solid ${SKF_G_003};
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &:last-child {
      text-align: right;
    }
  }
`;

export const NumberSelector = styled(InputNumber)`
  .ant-input-number {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }
  .ant-input-number-input {
    background: ${SKF_G_004};
    color: #fff;
    border-radius: 0;
    text-align: center;
    :&: hover {
      border-color: #fff;
    }
  }
  .ant-input-number-group-addon {
    padding: 0 5px;
  }
`;
