import styled from "styled-components";
import { Collapse } from "antd";

export const CollapseComp = styled(Collapse)`
  .ant-collapse-header-text,
  .ant-collapse-expand-icon,
  .ant-collapse-content-box {
    color: #fff;
  }
  .ant-collapse-header-text {
    font-weight: bolder;
  }
`;
