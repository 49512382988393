import React, { useState } from "react";
import { Form, Input, Card, Button, message } from "antd";
import { apiPutMember } from "api/api";
import { useNavigate } from "react-router-dom";

const ResetPsd = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const confirm = async (values) => {
    delete values.confirm;

    setIsLoading(true);
    try {
      const res = await apiPutMember(values);
      console.log(res);
      sessionStorage.removeItem("temp_t");
      setTimeout(() => {
        navigate("/home");
      }, 1500);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = (values) => {
    console.log("valueeessss", values);
    confirm(values);
  };
  return (
    <div className="md:p-40 mobile:p-4">
      <Card title={<h4>重新設定你的密碼</h4>} className="md:w-1/2 mx-auto">
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="請輸入新密碼" className="p-2" />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="請再次輸入新密碼" className="p-2" />
          </Form.Item>
          <Form.Item noStyle>
            <Button
              htmlType="submit"
              type="primary"
              className="w-full bg-black"
              loading={isLoading}
            >
              更新Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ResetPsd;
